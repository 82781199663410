import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Container from '../../components/container';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Headline from '../../components/headline';

const Programas = ({ data }) => (
  <Layout>
    <SEO
      title="Hilos de Amor"
      description="Para donar cabello que aún no ha sido cortado debe cumplir con las siguientes características ya que las pelucas que nosotros entregamos son a gusto de las niñas y siempre las piden de cabello largo."
    />
    <Image fluid={data.headerImage.childImageSharp.fluid} />
    <Container>
      <h1>Hilos de Amor</h1>
      <p>
        Para donar cabello que aún no ha sido cortado debe cumplir con las
        siguientes características ya que las pelucas que nosotros entregamos
        son a gusto de las niñas y siempre las piden de cabello largo.
      </p>
      <ul>
        <li>Limpio y totalmente seco.</li>
        <li>
          Amarrado en dos coletas (izquierda y derecha), con dos ligas de
          papeleria cada una.
        </li>
        <li>
          Importante que no la trenzen porque así no podremos distinguir la
          textura original del cabello.
        </li>
        <li>
          Las coletas debe medir mínimo 45 CM, si ven la imagen son pelucas de
          cabello 100% natural.
        </li>
      </ul>
      <p>
        Al hacer una peluca se pierden de 10 a 15 CM de largo entre la costura y
        el corte final.
      </p>
      <p>Se necesitan de 6 a 8 cabelleras para hacer 1 peluca.</p>
    </Container>
  </Layout>
);

Programas.propTypes = {
  data: PropTypes.shape({
    headerImage: PropTypes.object.isRequired,
  }).isRequired,
};

export default Programas;

const Image = styled(Img)`
  margin-bottom: 3rem;
`;

const Programs = styled.div`
  display: grid;
  grid-template-areas: 1fr 1fr;
`;

const Card = styled.div``;

export const query = graphql`
  query {
    headerImage: file(relativePath: { regex: "/hilosdeamor.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 80, maxWidth: 1920, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
